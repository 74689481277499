import projectCellImg from "../assets/images/sample_project.png"
import projectDetailBtn from "../assets/images/project_detail_btn.svg"
import "../assets/stylesheets/ProjectCell.css"

const ProjectCell = () => {
   return (
       <div className="project_cell_wrapper">
           <img className="project_cell_image" src={projectCellImg}/>
           <div className="button_wrapper">
               <div className="project_cell_button tag">Landing Page</div>
               <div className="project_cell_button tag">UI & UX</div>
               <img className="project_cell_button_img" src={projectDetailBtn}/>
           </div>
       </div>
   )
};

export default ProjectCell;