import "../assets/stylesheets/CompanyListCell.css"

const CompanyListCell = ({companyTitle, description}) => {
    return (
        <div className="company_cell_wrapper">
            <div className="company_title">{companyTitle}</div>
            <div className="job_description">{description}</div>
        </div>
    )
}

export default CompanyListCell;