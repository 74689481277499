import React from 'react';
import '../assets/stylesheets/KeySkills.css'
import KeySkillCell from "./KeySkillCell";

const KeySkills = React.forwardRef((props, ref) => {
    return (
        <div className="container key_skills fade-in-initial" ref={ref}>
            <div className="header">
                <div className="header_title">Key Skills</div>
            </div>
            <div className="key_skills_description">
                I love to craft functional solutions for unique problems.<br/>
                These are some skills I've picked up over my career.
            </div>
            <div className="skill_cell_row">
                <KeySkillCell/>
                <KeySkillCell/>
                <KeySkillCell/>
            </div>
            <div className="skill_cell_row">
                <KeySkillCell/>
                <KeySkillCell/>
                <KeySkillCell/>
            </div>
        </div>
    )
})

export default KeySkills;