import React from 'react'
import '../assets/stylesheets/AboutMe.css'
import profile from "../assets/images/profile.png"
import icResume from "../assets/images/ic_resume.svg"
import CompanyListCell from "./CompanyListCell";

const AboutMe = React.forwardRef((props, ref) => {
    return (
        <div className="container about_me fade-in-initial" ref={ref}>
            <div className="description_wrapper">
                <div className="header">
                    <div className="header_title">About Me</div>
                    <div className="header_ic_wrapper">
                        <img className="header_ic" src={icResume}/>
                        <img className="header_ic" src={icResume}/>
                    </div>
                </div>
                <div className="comment top">
                    Hi, I am Ryan Park!
                    I'm a full-stack developer with 5 years of experience in web/app services.<br/>
                    I have led teams as a tech lead, successfully completing numerous projects and designing, developing, and operating various production services.
                </div>
                <div className="box_content_row">
                    <div className="comment bottom">
                        Driven by a passion for problem-solving, I stay updated on the latest technology trends to continuously grow my career.<br/>
                        I am eager to take on new challenges and create innovative, valuable services with my team.
                    </div>
                    <img className="about_me_img" src={profile}/>
                </div>
            </div>
            <div className="company_row">
                <CompanyListCell companyTitle="Hana TI Comp." description="Platform Dev. - 2018"/>
                <CompanyListCell companyTitle="Freewheelin." description="AI Dev. - 2019"/>
                <CompanyListCell companyTitle="AIARA Corp." description="Tech Lead - 2022"/>
                <CompanyListCell companyTitle="NINEFIVE." description="Senior Researcher - 2023"/>
            </div>
        </div>
    );
});

export default AboutMe;