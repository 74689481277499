import '../assets/stylesheets/KeyVisual.css'
import Gnb from "./Gnb";
import kvScrollDownIc from "../assets/images/kv_scroll_down_ic.svg"
import KeyVisualBtn from "./KeyVisualBtn";

const KeyVisual = ({kvBtnOnclick}) => {
    return (
        <div className="container kv">
            <Gnb />
            <div className="kv_title_container">
                <div className="kv_title_row">
                    <div className="dark">Crafting</div>
                    <div className="light">&nbsp;Solutions,</div>
                </div>
                <div className="kv_title_row">
                    <div className="dark">Building Futures with</div>
                    <div className="light">&nbsp;Code.</div>
                </div>
            </div>
            <KeyVisualBtn onClick={kvBtnOnclick}/>
            <img className="kv_scroll_down_btn" src={kvScrollDownIc}/>
        </div>
    );
}

export default KeyVisual;