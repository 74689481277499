import React, {useEffect, useRef} from 'react';
import './assets/stylesheets/App.css';
import KeyVisual from "./components/KeyVisual";
import AboutMe from "./components/AboutMe";
import Project from "./components/Project";
import KeySkills from "./components/KeySkills";

const App = () => {
    const aboutMeRef = useRef(null);
    const observer = useRef(null);
    const projectRef = useRef(null);
    const keySkillRef = useRef(null);

    useEffect(() => {
        observer.current = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fade-in'); // Add fade-in class when intersecting
                    }
                    // else {
                    //     entry.target.classList.remove('fade-in'); // Remove fade-in class when not intersecting
                    // }
                });
            },
            {threshold: 0.5} // Trigger fade-in when 50% of the section is visible
        );

        // Observe AboutMe section
        if (aboutMeRef.current) {
            observer.current.observe(aboutMeRef.current);
        }
        if (projectRef.current) {
            observer.current.observe(projectRef.current);
        }
        if (keySkillRef.current) {
            observer.current.observe(keySkillRef.current);
        }

        // Clean up observer on component unmount
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, []);

    const handleButtonClick = () => {
        if (aboutMeRef.current) {
            aboutMeRef.current.scrollIntoView({behavior: 'smooth'});
        }
    };
    return (
        <>
            <KeyVisual kvBtnOnclick={handleButtonClick}/>
            <AboutMe ref={aboutMeRef}/>
            <Project ref={projectRef}/>
            <KeySkills ref={keySkillRef}/>

        </>
    );
}

export default App;
