import React from 'react';
import '../assets/stylesheets/Project.css'
import icResume from "../assets/images/ic_resume.svg";
import ProjectCell from "./ProjectCell";

const Project = React.forwardRef((props, ref) => {
    return(
        <div className="container project fade-in-initial" ref={ref}>
            <div className="header">
                <div className="header_title">Featured Project</div>
            </div>
            <div className="project_description">
                In designing, developing, and operating production services, <br/>
                I have prioritized user experience, creating and implementing stable and scalable products.
            </div>
            <div className="project_cell_row">
                <ProjectCell/>
                <ProjectCell/>
            </div>
            <div className="project_cell_row">
                <ProjectCell/>
                <ProjectCell/>
            </div>
            <div className="project_expend_button_wrapper">
                <div className="project_expend_button">Explore all the project</div>
            </div>
        </div>
    )
})

export default Project;