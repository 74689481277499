import '../assets/stylesheets/Gnb.css'
import gnbMenuIc from '../assets/images/gnb_menu_ic.png'

function Gnb() {
    const logoClickListener = () => {
        window.location.href = "#";
    };

    return (
        <div className="wrapper">
            <div className="title_wrapper" onClick={logoClickListener}>
                <div className="title">Ryan Park</div>
                <div className="dot">.</div>
            </div>
            <img className="gnb_icon" src={gnbMenuIc}/>
        </div>
    )
}

export default Gnb;