import React from 'react';
import "../assets/stylesheets/KeyVisualBtn.css"
import kvBtnIc from "../assets/images/kv_btn_ic.svg"

const KeyVisualBtn = ({ onClick }) => {
    return (
        // <img className="kv_button_ic" src={kvBtnIc} onClick={onClick} alt="Button Icon" />
        <div className="kv_button" onClick={onClick}>
            <div className="kv_button_text">
                Say Hi
            </div>
            <img className="kv_button_ic" src={kvBtnIc}/>
        </div>
    );
};

export default KeyVisualBtn;