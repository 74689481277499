import "../assets/stylesheets/KeySkillCell.css"
import skillSampleImg from "../assets/images/skill_img_sample.svg"
import React from "react";

const KeySkillCell = () => {
    return (
        <div className="key_skill_cell_wrapper">
            <img className="skill_image" src={skillSampleImg}/>
            <div className="skill_title">React.js</div>
            <div className="skill_description">As an experienced Elementor developer, I provide web development services that encompass website cloning, redesign, and a full website.</div>
        </div>
    )
}

export default KeySkillCell;